
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ExercisesMonthChart from "./ExercisesMonthChart.vue";
import ExercisesSubjectChart from "./ExercisesSubjectChart.vue";
import { AxiosRequestConfig } from "axios";
import { VueEditor } from "vue3-editor";
import { event } from "vue-gtag";
import { useStore } from "vuex";

interface Subject {
  _id: string;
  name: string;
}

interface Classroom {
  _id: string;
  name: string;
}

interface Teacher {
  _id: string;
  name: string;
}

interface Exercise {
  _id: string;
  title: string;
  createdAt: string;
  subject: Subject;
  classrooms: Classroom[];
  module: string;
  teacher: Teacher;
  status: string;
  reviewDate: string;
  reviewNote: string;
  answers: any[];
  type: string;
}

export default defineComponent({
  name: "advisor-exercises",
  components: { ExercisesMonthChart, ExercisesSubjectChart, VueEditor },
  setup() {
    const { t } = useI18n();
    const exercises = ref<Exercise[]>([]);
    const filteredExercises = ref<Exercise[]>([]);

    const selectedExercise = ref<Exercise>({
      _id: "",
      title: "",
      createdAt: "",
      subject: {
        _id: "",
        name: "",
      },
      classrooms: [],
      module: "",
      teacher: {
        _id: "",
        name: "",
      },
      status: "",
      reviewDate: "",
      reviewNote: "",
      answers: [],
      type: "",
    });

    const status = ref("notValidated");
    const reason = ref("");

    const search = ref<string>("");
    const selectedClass = ref<string>("");
    const selectedTeacher = ref<string>("");

    const subjects = ref<Subject[]>([]);
    const classrooms = ref<Classroom[]>([]);
    const teachers = ref<Teacher[]>([]);

    const selectedSubject = ref<string>("");
    const loading = ref(true);

    const validateStat = ref(0);
    const store = useStore();

    const advisorAddExercice = computed(
      () => store.getters.currentUser.advisorAddExercice
    );

    ApiService.get("/lms/advisor/homeworks")
      .then(({ data }) => {
        data.forEach((exercise: Exercise) => {
          exercise.type = "exercise";
          exercises.value.push(exercise);

          if (
            !subjects.value.find((s: Subject) => s._id == exercise.subject._id)
          )
            subjects.value.push(exercise.subject);

          if (
            !teachers.value.find((t: Teacher) => t._id == exercise.teacher._id)
          )
            teachers.value.push(exercise.teacher);

          for (const classroom of exercise.classrooms) {
            if (
              !classrooms.value.find((c: Classroom) => c._id == classroom._id)
            )
              classrooms.value.push(classroom);
          }
        });

        ApiService.get("/lms/advisor/quizzes")
          .then(({ data }) => {
            data.forEach((exercise: Exercise) => {
              exercise.type = "quiz";
              exercises.value.push(exercise);

              if (
                !subjects.value.find(
                  (s: Subject) => s._id == exercise.subject._id
                )
              )
                subjects.value.push(exercise.subject);

              if (
                !teachers.value.find(
                  (t: Teacher) => t._id == exercise.teacher._id
                )
              )
                teachers.value.push(exercise.teacher);

              for (const classroom of exercise.classrooms) {
                if (
                  !classrooms.value.find(
                    (c: Classroom) => c._id == classroom._id
                  )
                )
                  classrooms.value.push(classroom);
              }
            });
            exercises.value = exercises.value.sort(
              (a, b) =>
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
            );
            filteredExercises.value = [...exercises.value];
          })
          .catch((e) => console.log(e))
          .finally(() => {
            loading.value = false;
          });
      })
      .catch((e) => console.log(e));
    const filterExercises = () => {
      currentPage.value = 1;
      filteredExercises.value = exercises.value.filter((exercise) => {
        const toSearch = search.value.toLowerCase();
        return (
          exercise.title.toLowerCase().includes(toSearch) &&
          (selectedClass.value == "" ||
            exercise.classrooms.find((c) => c._id == selectedClass.value)) &&
          (exercise.subject._id == selectedSubject.value ||
            selectedSubject.value == "") &&
          (exercise.teacher._id == selectedTeacher.value ||
            selectedTeacher.value == "") &&
          ((exercise.status === "validated" && validateStat.value == 1) ||
            (exercise.status === "notValidated" && validateStat.value == 2) ||
            (exercise.status === "rejected" && validateStat.value == 3) ||
            validateStat.value == 0)
        );
      });
    };

    const getClassroomExercises = (id: string) => {
      return exercises.value.filter((exercise: Exercise) =>
        exercise.classrooms.find((c) => c._id == id)
      ).length;
    };

    onMounted(() => {
      setCurrentPageTitle(t("course.exercisesValidation"));
    });

    const perPage = 7;

    const currentPage = ref<number>(1);

    const totalPages = computed(() =>
      Math.ceil(filteredExercises.value.length / perPage)
    );

    const pageRecords = computed(() => {
      const startIndex = perPage * (currentPage.value - 1) + 1;
      const endIndex = startIndex + perPage - 1;
      return filteredExercises.value.slice(startIndex - 1, endIndex);
    });

    const saveReview = () => {
      Swal.fire({
        title:
          status.value == "rejected"
            ? t(`course.confirmRejectHomework`)
            : t(`course.confirmValidationHomework`),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: status.value == "rejected" ? "#ff3333" : "#47BE7D",
        cancelButtonColor: "#3085d6",
        confirmButtonText:
          status.value == "rejected"
            ? t(`course.acceptReject`)
            : t(`course.acceptValidate`),
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.patch(
            `/lms/advisor/review${
              selectedExercise.value.type == "quiz" ? "Quiz" : "Homework"
            }/` + selectedExercise.value._id,
            {
              status: status.value,
              reviewNote: reason.value,
            } as AxiosRequestConfig
          )
            .then(() => {
              event("Review exercice", {
                event_category: "Exercice",
                event_label: "Exercice section",
                value: 1,
              });
              Swal.fire(
                status.value == "rejected"
                  ? t(`course.rejected`)
                  : t(`course.validated`),
                status.value == "rejected"
                  ? t("course.rejectedHomeworkInfo")
                  : t("course.validatedHomeworkInfo"),
                "success"
              ).then(() => {
                selectedExercise.value.status = status.value;
                selectedExercise.value.reviewDate = new Date().toString();
              });
            })
            .catch((e) => console.log(e));
        }
      });
    };

    return {
      t,
      advisorAddExercice,
      selectedTeacher,
      exercises,
      search,
      moment,
      filterExercises,
      filteredExercises,
      selectedClass,
      getClassroomExercises,
      perPage,
      currentPage,
      totalPages,
      pageRecords,
      subjects,
      selectedSubject,
      loading,
      classrooms,
      teachers,
      saveReview,
      validateStat,
      selectedExercise,
      status,
      reason,
    };
  },
});
